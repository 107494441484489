import * as dompack from 'dompack';
import * as mso from '@mod-mso/webdesigns/mso/mso';
import '@mod-mso/webdesigns/mso/mso.scss';
import './widgets/widgets';

import './lorje.scss';

dompack.onDomReady(() => {
  new mso.MSOSite();
});
